@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~primevue/resources/primevue.min.css";
@import "~primevue/resources/themes/tailwind-light/theme.css";

@layer base {
    :root {
        font-family: 'Avenir', sans-serif;
    }
    html, body, #app { height: 100%; }
    body { overflow: hidden; }
}

@layer utilities {
    .text-last-center {
        text-align-last: center;
    }
    .text-last-left {
        text-align-last: left;
    }
}

.repeating-logo {
    -webkit-mask-image: url('../images/lookup/logo-repeating-background.svg');
    mask-image: url('../images/lookup/logo-repeating-background.svg');
    -webkit-mask-size: 73px;
}

@media screen and (min-width:1280px) {
    .repeating-logo {
        -webkit-mask-size: 122px;
    }
}